import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout>
    <div>
      <ul>
        <li>Web Design</li>
        <li>Logo Design</li>
        <li>Print Media</li>
        <li>Photo Editing</li>
        <li>Character Design</li>
        <li>Illustration</li>
        <li>2D Animation</li>
        <li>3D Animation</li>
      </ul>
    </div>
  </Layout>
)
